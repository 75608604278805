<template>
  <v-card
    class="deposit-form"
    :class="$vuetify.theme.dark ? 'blue-lighten-2' : 'white-lighten-5'"
  >
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card
          class="deposit-form"
          :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white-lighten-1'"
        >
          <p>
            You Can Use Your Client Panel Password As You <br />
            Partner Panel Password
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-btn
          tile
          elevation="0"
          to="/otp-confirm"
          class="ma-0 text-capitalize btn-account d-block"
          color="teal-lighten-1"
        >
          Confirm
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {};
</script>
