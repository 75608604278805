<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'">
    <v-row dense>
      <v-col cols="12" sm="8" md="9" lg="9">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Search anything here ..."
          single-line
          solo
          hide-seaClientss
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="3">
        <ul>
          <li>
            <span class="text--ash">Total Trade:</span>
            <span>123</span>
          </li>

          <li>
            <span class="text--ash">Earning:</span>
            <span>150.50</span>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="12">
        <v-card
          class="rounded-0 elevation-0"
          :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
        >
          <v-sheet
            class="transactionTable levelOneTable"
            :class="$vuetify.theme.dark ? 'blue-lighten-1 ' : 'white-lighten-1'"
          >
            <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              :items-per-page="10"
            >
              <template v-slot:item.symb="{ item }">
                <div class="user-profile">
                  <div class="profile-image">
                    <v-badge
                      bordered
                      overlap
                      small
                      icon="mdi-star-outline"
                      size="10"
                      :class="verifyBadge(item.symb)"
                    >
                      <v-avatar size="32" class="indigo-darken-2">
                        <span class="text-small">L3</span>
                      </v-avatar>
                    </v-badge>
                  </div>
                </div>
              </template>
              <template v-slot:item.clientName="{ item }">
                <router-link class="text-capitalize text--green" :to="item.clientLink">
                  {{ item.clientName }}
                </router-link>
              </template>
              <template v-slot:item.ibName="{ item }">
                <router-link class="text-capitalize ib-links" :to="item.ibLink">
                  {{ item.ibName }}
                </router-link>
              </template>
              <template v-slot:item.commission="{ item }">
                <span class="text-capitalize text-decoration-none text--green">
                  {{ item.commission }}
                </span>
              </template>
              <template v-slot:item.seaClients="{ item }">
                <v-btn outlined small :class="verifyClients(item.seaClients)">
                  <router-link class="see-clients-links text-decoration-none text-capitalize" :to="item.seaClientsLinks">
                  See Clients: 
                    <span>{{ item.seaClientsNumber }}</span>
                </router-link>
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Symb",
          align: "center",
          value: "symb",
        },
        { text: "Client Name", value: "clientName", align: "center" },
        { text: "Parent Name", value: "ibName", align: "center"  },
        { text: "Reg Date & TIme", value: "dateAndTime", align: "center"  },
        { text: "Acc", value: "accType", align: "center"  },
        { text: "Balance", value: "balance", align: "center"  },

        { text: "Commission", value: "commission", align: "center"  },
        { text: "See Clients", value: "seaClients", align: "center"  },
      ],
      desserts: [
        {
          symb: "isPresentLevelBadge",
          clientName: "Adam",
          clientLink: '/adam',
          ibName: "Abu Bakar",
          ibLink: "/abu-bakar",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 22,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "isPresentLevelBadge",
          clientName: "Edris",
          clientLink: '/edris',
          ibName: "Umor",
          ibLink: "/umor",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 32,
         seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "",
          clientName: "Musa",
          clientLink: '/musa',
          ibName: "Osman",
          ibLink: "/osman",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 18,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients"
        },
        {
          symb: "isPresentLevelBadge",
          clientName: "Ibrahim",
          clientLink: '/ibrahim',
          ibName: "Ali",
          ibLink: "/ali",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
         commission: "10 EUR",
          seaClientsNumber: 12,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "",
          clientName: "Yousuf",
          clientLink: '/yousuf',
          ibName: "Abbas",
          ibLink: "/abbas",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
         commission: "10 EUR",
          seaClientsNumber: 0,
          seaClientsLinks:"",
          seaClients: "",
        },
        {
          symb: "isPresentLevelBadge",
          clientName: "Esmail",
          clientLink: '/esmail',
          ibName: "Yazid",
          ibLink: "/yazid",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 39,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "",
          clientName: "Yakub",
          clientLink: '/yakub',
          ibName: "Muabia",
          ibLink: "/muabia",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 34,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "isPresentLevelBadge",
          clientName: "Nuh",
          clientLink: '/nuh',
          ibName: "Huraira",
          ibLink: "/huraira",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 41,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "isPresentLevelBadge",
          clientName: "Suaib",
          clientLink: '/suaib',
          ibName: "Hamza",
          ibLink: "/hamza",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 15,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
        {
          symb: "isPresentLevelBadge",
          clientName: "Harun",
          clientLink: '/harun',
          ibName: "Khalid",
          ibLink: "/khalid",
          dateAndTime: "25.08.2020-8:32 pm",
          accType: "2132 (test)",
          balance: "100 EUR",
          commission: "10 EUR",
          seaClientsNumber: 23,
          seaClientsLinks:"/client-tree-level-four",
          seaClients: "activeClients",
        },
      ],
    };
  },
  methods: {
    verifyBadge(symb) {
      if (symb === "isPresentLevelBadge") return "show-badge";
      else return "hide-badge";
    },
    verifyClients(seaClients) {
      if (seaClients === "activeClients") return "active-clients-btn";
      else return "inActive-clients-btn";
    },
  },
};
</script>
