<template>
  <v-container class="main-container responsive-height">
    <v-row dense>
      <v-col cols="12" xl="12">
        <v-sheet
          class="apexChartWrapper commissionStatusChart"
          :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
        >
          <CurrencyChart></CurrencyChart>
          <LevelWiseChart></LevelWiseChart>
          <AccountTypeChart></AccountTypeChart>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountTypeChart from '@/components/Commission/AccountTypeChart.vue';
import CurrencyChart from '@/components/Commission/CurrencyChart.vue';
import LevelWiseChart from '@/components/Commission/LevelWiseChart.vue';
export default {
  components: {
    CurrencyChart,
    LevelWiseChart,
    AccountTypeChart,
  },
};
</script>
