<template>
  <v-row dense>
    <v-col cols="12" xl="12">
      <v-sheet
        class="apexChartWrapper"
        :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
      >
        <div class="chart-title-bar">
          <h3>Level Clients & Earnings</h3>
          <router-link class="links text--green pr-4" to="/transaction-history"
            >See Details ></router-link
          >
        </div>
        <div class="pt-3">
          <apexcharts
            width="100%"
            height="350"
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexcharts>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "basic-bar",
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "Jan.2022",
            "Feb.2022",
            "Mar.2022",
            "Apr.2022",
            "May.2022",
            "Jun.2022",
            "Jul.2022",
            "Aug.2022",
          ],
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: [
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
              ],
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: false,
            color: "transparent",
            height: 0,
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          fontFamily: "Inter",
          fontSize: "14px",
          labels: {
            colors: "#708AA7",
            useSeriesColors: false,
          },
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val / 1000 + "K";
            },
            style: {
              colors: ["#708AA7"],
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 500,
            },
          },
          axisBorder: {
            show: false,
          },
        },
        colors: ["#14D7B2", "#1485D7", "#6914d5", "#9814ad", "#647e98"],
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
        grid: {
          borderColor: "#1C2C40",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 7,
          colors: ["transparent"],
        },
      },
      series: [
        {
          name: "Net Profit",
          data: [52000, 20000, 75000, 50000, 90000, 100000, 82000, 98100],
        },
        {
          name: "Revenue",
          data: [31000, 30000, 18000, 82000, 50000, 80000, 72000, 45200],
        },
        {
          name: "Free Cash Flow",
          data: [20000, 50000, 87000, 29000, 60000, 29000, 91000, 65400],
        },
        {
          name: "Down Payment",
          data: [10000, 80000, 73000, 81000, 40000, 80000, 52000, 63900],
        },
        {
          name: "Up Payment",
          data: [57000, 100000, 91000, 51000, 80000, 57000, 49000, 27200],
        },
      ],
    };
  },
};
</script>


