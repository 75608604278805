<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'">
    <InternalTransOutTabForm></InternalTransOutTabForm>
    <InternalTransOutTabTable></InternalTransOutTabTable>
  </v-card>
</template>
<script>
import InternalTransOutTabForm from "./InternalTransOutTabForm.vue";
import InternalTransOutTabTable from "./InternalTransOutTabTable.vue";

export default {
  components: {
    InternalTransOutTabForm,
    InternalTransOutTabTable,
  },
};
</script>