<template>
  <v-container class="main-container responsive-height">
    <UserStatistics></UserStatistics>
    <AllLinks></AllLinks>
    <IbCommissionTable></IbCommissionTable>
    <ClientLevelChart></ClientLevelChart>
  </v-container>
</template>

<script>
import AllLinks from "@/components/Dashboard/AllLinks.vue";
import ClientLevelChart from "@/components/Dashboard/ClientLevelChart.vue";
import IbCommissionTable from "@/components/Dashboard/IbCommissionTable.vue";
import UserStatistics from "@/components/Dashboard/UserStatistics.vue";
export default {
  components: {
    UserStatistics,
    AllLinks,
    IbCommissionTable,
    ClientLevelChart,
  },
};
</script>
