<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'">
    <v-row dense>
      <v-col cols="12" sm="12" md="5" lg="6">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Search anything here ..."
          single-line
          solo
          hide-seaClientss
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
          class=""
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              append-icon="mdi-chevron-down"
              readonly
              v-bind="attrs"
              v-on="on"
              solo
              class="custom-select"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            class="custom-date-picker"
            scrollable
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              class="error-text-lighten-1"
              text
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              
              text
              @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <ul>
          <li>
            <span class="text--ash">Total Trade:</span>
            <span>123</span>
          </li>

          <li>
            <span class="text--ash">Earning:</span>
            <span>150.50</span>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="12">
        <v-card
          class="rounded-0 elevation-0"
          :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
        >
          <v-sheet
            class="apexChartWrapper"
            :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
          >
          <div class="chart-title-bar">
            <h3>Level Clients & Earnings</h3>
          </div>
            <div class="pt-3">
              <apexcharts
                width="100%"
                height="350"
                type="bar"
                :options="chartOptions"
                :series="series"
              ></apexcharts>
            </div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      search: "",
      chartOptions: {
        chart: {
          id: "basic-bar",
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "Jan.2022",
            "Feb.2022",
            "Mar.2022",
            "Apr.2022",
            "May.2022",
            "Jun.2022",
            "Jul.2022",
            "Aug.2022",
          ],
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: [
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
                "#708AA7",
              ],
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: false,
            color: "transparent",
            height: 0,
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          fontFamily: "Inter",
          fontSize: "14px",
          labels: {
            colors: "#708AA7",
            useSeriesColors: false,
          },
          markers: {
            width: 8,
            height: 8,
            radius: 8,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val / 1000 + "K";
            },
            style: {
              colors: ["#708AA7"],
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 500,
            },
          },
          axisBorder: {
            show: false,
          },
        },
        colors: ["#14D7B2", "#1485D7", "#6914d5", "#9814ad", "#647e98"],
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
        grid: {
          borderColor: "#1C2C40",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 7,
          colors: ["transparent"],
        },
      },
      series: [
        {
          name: "Net Profit",
          data: [52000, 20000, 75000, 50000, 90000, 100000, 82000, 98100],
        },
        {
          name: "Revenue",
          data: [31000, 30000, 18000, 82000, 50000, 80000, 72000, 45200],
        },
        {
          name: "Free Cash Flow",
          data: [20000, 50000, 87000, 29000, 60000, 29000, 91000, 65400],
        },
        {
          name: "Down Payment",
          data: [10000, 80000, 73000, 81000, 40000, 80000, 52000, 63900],
        },
        {
          name: "Up Payment",
          data: [57000, 100000, 91000, 51000, 80000, 57000, 49000, 27200],
        },
      ],
      date: ["2022-01-01", "2023-01-01"],
      modal: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.date.join(" ~ ");
    },
  },
};
</script>
