<template>
  <v-row dense>
    <v-col cols="12" md="12">
      <v-card
        tile
        flat
        class="userBriefHistory"
        :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
      >
        <v-container>
          <v-row dense>
            <!-- Start Title with Modal -->
            <v-col cols="12">
              <v-sheet
                class="d-flex align-center mb-4 justify-space-between flex-column flex-sm-row"
                :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'"
              >
                <!-- Title -->
                <div class="title">
                  <v-img class="mr-3"
                    max-width="17"
                    src="@/assets/Dashboard/bar.svg"
                  ></v-img>
                  <span>Hello, MK Hasan (ID:5001)</span>
                </div>
                <!-- Modal -->
                <div class="startingTips">
                  <v-dialog
                    transition="scale-transition"
                    origin="center center"
                    max-width="554"
                    content-class="deposit-withdraw-popup"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        plain
                        class="text-capitalize pr-0"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-img
                          class="mr-1"
                          max-width="14"
                          src="@/assets/Dashboard/tips.svg"
                        ></v-img>
                        <b>Starting Tips</b></v-btn
                      >
                    </template>
                    <template v-slot:default="dialog">
                      <v-card
                        class="rounded-0"
                        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'secondary2'"
                      >
                        <v-card-title class="rounded-0"
                          >Starting Tips</v-card-title
                        >
                        <ol>
                          <li v-for="detail in details" :key="detail.id">
                            {{ detail.para }}
                          </li>
                        </ol>
                      </v-card>
                    </template>
                  </v-dialog>
                </div>
              </v-sheet>
            </v-col>
            <!-- End Title with Modal -->

            <!-- Start User Statistics History -->
            <v-col cols="6" sm="6" md="3" v-for="card in cards" :key="card.id">
              <v-sheet
                :class="
                  $vuetify.theme.dark ? 'blue-lighten-1 ' : 'white-lighten-1'
                "
              >
                <v-card-text class="trade">
                  <div class="icon-wrapper">
                    <div class="icon-box">
                      <v-img :src="card.imageName" :alt="card.imageAlt"></v-img>
                    </div>
                    <div class="icon-content">
                      <h3>{{ card.title }}</h3>
                      <span>{{ card.subTitle }}</span>
                    </div>
                  </div>
                  <p class="amount">{{ card.amount }}</p>
                </v-card-text>
              </v-sheet>
            </v-col>
            <!-- Start User Statistics History -->
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    cards: [
      {
        id: 1,
        imageName: require("@/assets/Dashboard/balance.png"),
        imageAlt: "Balance",
        title: "Balance",
        subTitle: "Available",
        amount: "$16752",
      },
      {
        id: 2,
        imageName: require("@/assets/Dashboard/earning.png"),
        imageAlt: "Earning",
        title: "Earning",
        subTitle: "Total",
        amount: "$12000",
      },
      {
        id: 3,
        imageName: require("@/assets/Dashboard/referral.png"),
        imageAlt: "Referral",
        title: "Referral",
        subTitle: "Accounts",
        amount: "120",
      },
      {
        id: 4,
        imageName: require("@/assets/Dashboard/withdraw.png"),
        imageAlt: "Withdraw",
        title: "Withdraw",
        subTitle: "Total",
        amount: "$16752",
      },
    ],
    details: [
      {
        id: 1,
        para: "Payments from third parties are not accepted. The name of the sender must match the name on your trading account.",
      },
      {
        id: 2,
        para: "UITFX accepts wire transfers in USD. Funds sent in a different currency will be converted to the deposit currency of your account. You may be charged a fee for the conversion.",
      },
      {
        id: 3,
        para: "The sending bank or correspondent bank may deduct a fee for processing a wire transfer. Demo FX does not apply any fees and will deposit to your account the net amount received.",
      },
      {
        id: 4,
        para: " The sending bank or correspondent bank may deduct a fee for processing a wire transfer. UITFX does not apply any fees and will deposit to your account the net amount received.",
      },
      {
        id: 5,
        para: "If the transaction goes through, the funds will be posted to your trading account upon receipt by UITFX.",
      },
    ],
  }),
};
</script>
