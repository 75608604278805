<template>
   <v-row dense>
      <v-col cols="12" sm="12">
        <v-card
          class="pa-0 rounded-0 elevation-0"
          :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
        >
          <v-sheet
            class="transactionTable ib-commission-table"
            :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white-lighten-1'"
          >
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="Transaction"
            >
              <template v-slot:item.status="{ item }">
                <span small :style="getColor(item.status)">
                  {{ item.status }}
                </span>
              </template>
            </v-data-table>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        { text: "Date & Time", align: "start", value: "time" },
        { text: "Trx Type", value: "type" },
        { text: "Trx Method", value: "method" },
        { text: "Reference No", value: "reference" },
        { text: "Amount", value: "amount" },
        { text: "Trx.Status", value: "status" },
      ],
      Transaction: [
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        {
          time: "10.02.22-08.22 pm",
          type: "Withdraw",
          method: "Bank Trx.",
          reference: "1658839464",
          amount: "12",
          status: "Approved",
        },
        
      ],
    };
  },
  methods: {
    getColor(status) {
      if (status == "Approved")
        return "color: #02B58A; background-color: rgba(2, 181, 138, 0.15); padding: 8px 13px; border-radius: 2px;";
      else if (status == "Decline") return "color: red";
      else return "color: yellow";
    },
  },
};
</script>
