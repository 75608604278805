import Vue from 'vue';
import App from './App.vue';
import "./plugins/apexChart";
import vuetify from './plugins/vuetify';
import router from './router';
import './scss/main.scss';

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
