<template>
  <v-card
    class="pa-0 rounded-0 elevation-0"
    :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
  >
    <v-sheet
      class="transactionTable marketingTable"
      :class="$vuetify.theme.dark ? 'blue-darken-5 ' : 'white-lighten-1'"
    >
      <v-card-text class="d-flex justify-space-between px-3 pb-5">
        <v-card-title class="pa-0"> Uploaded Banners </v-card-title>
        <v-dialog
          transition="scale-transition"
          origin="center center"
          max-width="324"
          persistent
          v-model="dialog"
          content-class="deposit-withdraw-popup marketingPopup"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              tile
              small
              v-bind="attrs"
              v-on="on"
              elevation="0"
              class="text-capitalize btn-account"
              color="teal-lighten-1"
              >see details</v-btn
            >
          </template>

          <v-card
            class="rounded-0 pa-7"
            :class="$vuetify.theme.dark ? 'blue-darken-1' : 'white-lighten-5'"
          >
            <v-card-title class="pb-3 text-left justify-start pl-3">
              Upload Banner
            </v-card-title>
            <v-card-subtitle class="mt-0 pl-3 pb-0 pr-10 text--ash">
              You Can Upload New Banner From Here
            </v-card-subtitle>
            <v-card
              class="deposit-form px-3 py-4"
              :class="$vuetify.theme.dark ? 'blue-darken-1' : 'white-lighten-5'"
            >
              <v-row>
                <v-col cols="12">
                  <fieldset>
                    <legend>Category</legend>
                    <v-select
                      solo
                      :items="slots"
                      append-icon="mdi-chevron-down"
                      label="Click to Select"
                      class="custom-select"
                      hide-details
                    ></v-select>
                  </fieldset>
                </v-col>
                <v-col cols="12">
                  <fieldset>
                    <legend>Resolution</legend>
                    <v-select
                      solo
                      :items="slots2"
                      append-icon="mdi-chevron-down"
                      label="Click to Select"
                      class="custom-select"
                      hide-details
                    ></v-select>
                  </fieldset>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <div class="input-file">
                    <span>Upload Banner</span>
                    <v-file-input
                      label="Choose file"
                      filled
                      prepend-icon="mdi-file-image"
                      hide-details
                    ></v-file-input>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions>
              <v-row dense>
                <v-col cols="6">
                  <v-btn
                    tile
                    block
                    @click="dialog = false"
                    elevation="0"
                    class="text-capitalize btn-account"
                    color="teal-lighten-1"
                    >Save</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    tile
                    block
                    outlined
                    @click="dialog = false"
                    elevation="0"
                    class="text-capitalize btn-account"
                    >Close</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-data-table :headers="headers" hide-default-footer :items="Transaction">
        <template v-slot:item.action="{ item }">
          <div class="trade-dropdown">
            <v-menu
              rounded="0"
              class="trade-dropdown-menu"
              transition="slide-x-transition"
              bottom
              left
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  small
                  tile
                  elevation="0"
                  class="ma-0 text-capitalize btn-account"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon 
                    >mdi-dots-vertical</v-icon
                  >
                </v-btn>
              </template>
              <v-card class="dropdown-menu-card" tile flat>
                <v-list
                  flat
                  elevation="0"
                  :class="$vuetify.theme.dark ? '$blue-darken-1' : 'white-lighten-1'"
                >
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      :ripple="false"
                      link
                    >
                      <v-list-item-content>
                        <router-link
                          v-text="item.text"
                          class="text-decoration-none"
                          router
                          :to="item.link"
                        ></router-link>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </template>
        <template v-slot:item.bannerImage="{ item }">
          <img
            :src="item.bannerImage"
            :width="item.bannerWidth"
            :alt="item.bannerAlt"
          />
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      slots: ["A", "B", "C"],
      slots2: ["A", "B", "C"],
      dialog: false,
      items: [
        { text: "Edit", link: "" },
        { text: "Delete", link: "" },
      ],
      headers: [
        { text: "SL", align: "start", value: "sl" },
        { text: "Banner Category", value: "category" },
        { text: "Banner Resolution", value: "resolution" },
        { text: "Banner Resolution", value: "bannerImage" },
        { text: "Action", value: "action" },
      ],
      Transaction: [
        {
          sl: 1,
          category: "Static",
          resolution: "420*320",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 96,

          bannerAlt: "400x320",
        },
        {
          sl: 2,
          category: "Static",
          resolution: "1080*320",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 97,

          bannerAlt: "1080x320",
        },
        {
          sl: 3,
          category: "Static",
          resolution: "440*220",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 63,

          bannerAlt: "440x220",
        },
        {
          sl: 4,
          category: "Static",
          resolution: "200*100",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 98,

          bannerAlt: "200x100",
        },
        {
          sl: 5,
          category: "Static",
          resolution: "200*100",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 166,

          bannerAlt: "200x100",
        },
        {
          sl: 6,
          category: "Static",
          resolution: "800*500",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 98,

          bannerAlt: "800x500",
        },
        {
          sl: 7,
          category: "Static",
          resolution: "800*500",
          bannerImage:
            "https://www.communitychoicecu.com/globalassets/features/7-month-cd-5-small.png",
          bannerWidth: 99,

          bannerAlt: "800x500",
        },
      ],
    };
  },
};
</script>
