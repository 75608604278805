<template>
  <v-sheet :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'">
    <v-container class="main-container responsive-height">
      <v-row dense>
        <v-col cols="12" xl="12">
          <v-card elevation="0" class="all-trade-tab rounded-0 mt-2">
            <v-tabs
          
              :background-color="
                $vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'
              "
              class="pa-0"
            >
              <v-tab class="ml-0"> Live Trades</v-tab>
              <v-tab> Closed Trades </v-tab>
              <v-tab-item>
                <v-card flat class="mb-0">
                  <LiveTradesTab></LiveTradesTab>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="mb-0">
                  <CloseTradesTab></CloseTradesTab>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import CloseTradesTab from "@/components/MyClients/TradingHistory/CloseTradesTab.vue";
import LiveTradesTab from "@/components/MyClients/TradingHistory/LiveTradesTab.vue";

export default {
  data: () => ({
    
  }),
  components: {
    LiveTradesTab,
    CloseTradesTab,
  },
};
</script>
