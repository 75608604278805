<template>
  <form action="/action_page.php">
    <v-container class="main-container responsive-height">
      <v-row>
        <v-col xl="6" lg="6" md="7" sm="7" cols="12">
          <TransferAmount></TransferAmount>
        </v-col>
        <v-col xl="4" lg="4" md="5" sm="5" cols="12">
          <TransferChart></TransferChart>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
import TransferAmount from "@/components/Transactions/TransferAmount.vue";
import TransferChart from "@/components/Transactions/TransferChart.vue";

export default {
  components: {
    TransferAmount,
    TransferChart,
  },
};
</script>
