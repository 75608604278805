<template>
  <v-row dense>
    <v-col cols="12" sm="12">
      <v-card
        class="pa-0 rounded-0 elevation-0"
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
      >
        <v-sheet
          class="transactionTable ib-commission-table"
          :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white-lighten-1'"
        >
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="Transaction"
          ></v-data-table>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        { text: "Update Time", align: "start", value: "time" },
        { text: "Name", value: "amount" },
        { text: "IB Code", value: "type" },
        { text: "Account No", value: "method" },
        { text: "Country", value: "receiver" },
        { text: "Client Level", value: "sender" },
        { text: "Commission ($)", value: "reference" },
      ],
      Transaction: [
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
        {
          time: "10.02.22-08.22 pm",
          amount: "Alauddin Mollah",
          type: "34355344344",
          method: "34355344344",
          receiver: "Bangladesh",
          sender: "Level 1",
          reference: "$ 12",
        },
      ],
    };
  },
};
</script>
