<template>
  <v-row dense>
    <v-col lg="6" md="6" sm="6" cols="12">
      <UserInfo></UserInfo>
    </v-col>
    <v-col lg="6" md="6" sm="6" cols="12">
      <UserDetails></UserDetails>
    </v-col>
  </v-row>
</template>

<script>
import UserDetails from './UserDetails.vue';
import UserInfo from './UserInfo.vue';
export default {
  components: {
    UserInfo,
    UserDetails,
  },
};
</script>
