<template>
  <v-card
    class="user-profile-card"
    flat
    tile
    :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
  >
    <v-card-title>
      <v-icon class="pr-5" color="ash-lighten-4">mdi mdi-account-outline</v-icon>
      Bappy Mithun
    </v-card-title>
    <v-card-actions>
      <v-card-text class="py-0 pr-sm-0 flex-basic pl-sm-1">
        <v-avatar size="105">
          <v-img src="@/assets/user.png"></v-img>
        </v-avatar>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-card-subtitle :class="info.className" v-for="info in infos" :key="info.id">
          <span class="d-block">{{ info.title }} </span>
          <span>{{ info.subTitle }}</span>
        </v-card-subtitle>
      </v-card-text>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      infos: [
        {
          id:1,
          className: 'pa-0 mb-3',
          title: 'User ID',
          subTitle: '5020',
        },
        {
          id:2,
          className: 'pa-0',
          title: 'Email',
          subTitle: 'netcodenmithun@gmail.com',
        }
      ]
    };
  },
};
</script>
