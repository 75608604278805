<template>
  <div class="profileView">
    <v-container class="responsive-height custom-container">
      <ProfileBar></ProfileBar>
      <ProfileInfo></ProfileInfo>
      <ProfileVerification></ProfileVerification>
      <ProfileStatus></ProfileStatus>
    </v-container>
  </div>
</template>

<script>
import ProfileBar from "@/components/Profile/ProfileBar.vue";
import ProfileInfo from "@/components/Profile/ProfileInfo.vue";
import ProfileStatus from "@/components/Profile/ProfileStatus.vue";
import ProfileVerification from "@/components/Profile/ProfileVerification.vue";
export default {
  components: {
    ProfileBar,
    ProfileInfo,
    ProfileVerification,
    ProfileStatus,
  },
};
</script>
