<template>
  <v-container class="main-container responsive-height">
    <ClientUserStatistics></ClientUserStatistics>
    <ProfileBar></ProfileBar>
    <AllLevelTabs></AllLevelTabs>
  </v-container>
</template>

<script>
import AllLevelTabs from '@/components/MyClients/ClientTreeLevelFour/AllLevelTabs.vue';
import ClientUserStatistics from '@/components/MyClients/ClientTreeLevelFour/ClientUserStatistics.vue';
import ProfileBar from '@/components/MyClients/ClientsTreeAndEarning/ProfileBar.vue';

export default {
  components: {
    ClientUserStatistics,
    AllLevelTabs,
    ProfileBar,
  },
};
</script>
