<template>
  <v-card
    class="user-profile-card"
    flat
    tile
    :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
  >
    <v-card-title class="d-flex justify-space-between align-center">
      <span>Personal Information</span>
      <span class="edit">Go Client Portal to Edit</span>
    </v-card-title>
    <v-card-actions>
      <v-card-text class="py-0 pr-0">
        <v-card-text class="pa-0">
          <v-card-subtitle
            class="pa-0 mb-2 listing-v-card__subtitle"
            v-for="info in infos"
            :key="info.id"
          >
            <span> {{ info.title }}</span>
            <span>{{ info.subtitle }}</span>
          </v-card-subtitle>
        </v-card-text>
      </v-card-text>
      <v-card-text class="py-0 pl-sm-0 pl-md-4">
        <v-card-subtitle
          class="pa-0 mb-2 listing-v-card__subtitle"
          v-for="info2 in infos2"
          :key="info2.id"
        >
          <span> {{ info2.title }}</span>
          <span>{{ info2.subtitle }}</span>
        </v-card-subtitle>
      </v-card-text>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      infos: [
        {
          id: 1,
          title: "Country: ",
          subtitle: "Eritrea",
        },
        {
          id: 2,
          title: "Phone No: ",
          subtitle: "+1 181 491-2815",
        },
        {
          id: 3,
          title: "State: ",
          subtitle: "Dubai",
        },
        {
          id: 4,
          title: "Date of Birth: ",
          subtitle: "1988-06-10",
        },
      ],
      infos2: [
        {
          id: 1,
          title: "City: ",
          subtitle: "London",
        },
        {
          id: 2,
          title: "Zipcode: ",
          subtitle: "76757",
        },
        {
          id: 3,
          title: "Street Address: ",
          subtitle: "Occaecat est accusa",
        },
      ],
    };
  },
};
</script>
