<template>
  <v-sheet :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'">
    <v-container class="main-container responsive-height">
      <v-row dense>
        <v-col cols="12" xl="12">
          <v-card
            elevation="0"
            class="all-trade-tab rounded-0 pt-3 px-0"
            :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'"
          >
            <v-tabs
              :background-color="
                $vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'
              "
              class="pa-0"
            >
              <v-tab class="ml-0"> Static Banner</v-tab>
              <v-tab> Animated Banner </v-tab>
              <v-tab> HTML5 Banner </v-tab>
              <v-tab-item>
                <v-card flat class="mb-0">
                  <StaticBanner></StaticBanner>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <AnimatedBanner></AnimatedBanner>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <Html5Banner></Html5Banner>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import AnimatedBanner from "@/components/Marketing/AnimatedBanner.vue";
import Html5Banner from "@/components/Marketing/Html5Banner.vue";
import StaticBanner from "@/components/Marketing/StaticBanner.vue";

export default {
  data: () => ({}),
  components: {
    StaticBanner,
    AnimatedBanner,
    Html5Banner,
  },
};
</script>
