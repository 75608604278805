<template>
  <v-row class="no-gutters">
    <v-col cols="12" sm="6" md="6" lg="9">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Search anything here ..."
        single-line
        solo
        hide-details
        class="mt-4 mb-0"
      ></v-text-field>
    </v-col>
    <v-col cols="8" sm="4" md="4" lg="2">
      <v-select
        solo
        :items="slots"
        append-icon="mdi-chevron-down"
        label="All Groups"
        class="custom-select mt-4 mb-0"
      ></v-select>
    </v-col>
    <v-col cols="4" sm="2" md="2" lg="1">
      <ul class="mt-3">
        <li>
          <span>Total Profits:</span>
          <span>1500.50</span>
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      slots: ["7100001492(MICRO)", "7100006392(MICRO)", "7102006392(MICRO)"],
    };
  },
};
</script>
