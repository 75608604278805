<template>
  <v-stepper v-model="e1" class="password-stepper transform-password-stepper">
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="deposit-form"
          :class="$vuetify.theme.dark ? 'blue-lighten-2' : 'white-lighten-5'"
        >
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <fieldset>
                <legend>Transfer to</legend>
                <v-select
                  solo
                  :items="slots"
                  append-icon="mdi-chevron-down"
                  label="Select One"
                  class="custom-select"
                  hide-details
                ></v-select>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <fieldset>
                <legend>Enter Amount (USD)</legend>
                <v-text-field
                  label="Deposit Amount"
                  single-line
                  solo
                  hide-details
                  class="custom-select"
                ></v-text-field>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <fieldset>
                <legend>Amount in (INR)</legend>
                <v-text-field
                  label="Amount in Other Currency"
                  single-line
                  solo
                  hide-details
                  class="custom-select"
                ></v-text-field>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                tile
                elevation="0"
                class="ma-0 text-capitalize btn-account d-block w-100"
                color="teal-lighten-1"
                @click="e1 = 2"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card
          class="deposit-form"
          :class="$vuetify.theme.dark ? 'blue-lighten-2' : 'white-lighten-5'"
        >
          <p class="mb-5">
            A verification code is sent to
            <span class="text--green">mkhassan25@gmail.com</span>
          </p>

          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <fieldset>
                <legend>Enter Verification Code</legend>
                <v-text-field
                  label="Enter here"
                  single-line
                  solo
                  hide-details
                  class="custom-select"
                ></v-text-field>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                tile
                elevation="0"
                type="submit"
                class="ma-0 text-capitalize btn-account w-100"
                color="teal-lighten-1"
                @click="e1 = 2"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  data() {
    return {
      e1: 1,
      slots: [
        "9246390227 (super Pro) 340 USD",
        "9246820227 (Ultimate Pro) 30 USD",
        "9926390227 (Basic Pro) 940 USD",
      ],
      slots2: [
        "9246820227 (Ultimate Pro) 30 USD",
        "9926390227 (Basic Pro) 940 USD",
        "9246390227 (super Pro) 340 USD",
      ],
    };
  },
};
</script>
