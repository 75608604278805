<template>
  <v-menu bottom left transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :ripple="false"
        icon
        v-bind="attrs"
        v-on="on"
        class="no-background-hover text-capitalize mr-sm-5 mr-0"
        ><v-icon class="mr-2">mdi-web</v-icon> <span class="d-none d-sm-flex">Eng</span>
        <v-icon class="d-none d-sm-flex">mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list :class="$vuetify.theme.dark ? 'blue-lighten-2' : 'white-lighten-5'">
        <v-list-item-content class="pa-0">
          <v-list-item
            v-for="[icon, text] in links"
            :key="icon"
            link
            @click="() => {}"
          >
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-content>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    links: [
      ["mdi-inbox-arrow-down", "Eng"],
      ["mdi-send", "Hin"],
    ],
  }),
};
</script>
