<template>
  <v-row dense>
    <v-col cols="12">
      <v-card
        tile
        flat
        class="userBriefHistory"
        :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
      >
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-sheet
                class="d-flex align-center mb-4 justify-space-between flex-column flex-sm-row"
                :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'"
              >
                <div class="title">
                  <v-img class="mr-3"
                    max-width="17"
                    src="@/assets/Dashboard/bar.svg"
                  ></v-img>
                  <span>Hello, MK Hasan (ID:5001)</span>
                </div>
                <div class="client-info-tips">
                  <p class="mb-0">
                    <span class="text--green">Total Clients:</span> 501235
                  </p>
                </div>
              </v-sheet>
            </v-col>
            <v-col v-for="card in cards" :key="card.id">
              <v-sheet
                :class="
                  $vuetify.theme.dark ? 'blue-lighten-1 ' : 'white-lighten-1'
                "
              >
                <v-card-text class="trade">
                  <div class="icon-wrapper">
                    <div class="icon-box">
                      <v-img :src="card.imageName" :alt="card.imageAlt"></v-img>
                    </div>
                    <div class="icon-content">
                      <h3>{{ card.title }}</h3>
                      <span>{{ card.subTitle }}</span>
                    </div>
                  </div>
                  <p class="amount">{{ card.amount }}</p>
                </v-card-text>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    cards: [
      {
        id: 5,
        imageName: require("@/assets/Dashboard/level-5.svg"),
        imageAlt: "Level 5",
        title: "Level 5",
        subTitle: "Clients",
        amount: "3962",
      },
    ],
  }),
};
</script>
