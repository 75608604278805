<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'">
    <WithdrawTabForm></WithdrawTabForm>
    <WithdrawTabTable></WithdrawTabTable>
  </v-card>
</template>
<script>
import WithdrawTabForm from "./WithdrawTabForm.vue";
import WithdrawTabTable from "./WithdrawTabTable.vue";

export default {
  components: {
    WithdrawTabForm,
    WithdrawTabTable,
  },
};
</script>