<template>
  <v-row dense>
    <v-col lg="6" md="6" sm="12" cols="12">
      <v-card
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        class="listing-upload"
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4">
            <v-card-title>Date & Time</v-card-title>
            <v-card-subtitle>10/12-10:22pm</v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-card-title class="comment-title">Comment</v-card-title>
            <v-dialog
              transition="scale-transition"
              origin="center center"
              max-width="554"
              content-class="deposit-withdraw-popup"
              v-model="dialog2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="text-capitalize pa-0 see-details-btn"
                  v-bind="attrs"
                  v-on="on"
                  >See Details</v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog2 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="rounded-0">Comment</v-card-title>

                  <ol style="list-style-type: none">
                    <li>
                      Payments from third parties are not accepted. The name of
                      the sender must match the name on your trading account.
                    </li>
                  </ol>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <v-card-title class="d-block">Status</v-card-title>
            <v-card-subtitle class="approved-text">Approved</v-card-subtitle>
          </v-col>
          <v-col cols="1">
            <v-dialog max-width="554" v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="icon-btn"
                  ><v-icon class="teal-text-lighten-1"
                    >mdi mdi-file-image-outline</v-icon
                  ></v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0 bg-transparent"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    class="white--text align-end"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ></v-img>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        class="listing-upload"
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4">
            <v-card-title>Date & Time</v-card-title>
            <v-card-subtitle>10/12-10:22pm</v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-card-title class="comment-title">Comment</v-card-title>
            <v-dialog
              transition="scale-transition"
              origin="center center"
              max-width="554"
              content-class="deposit-withdraw-popup"
              v-model="dialog3"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="text-capitalize pa-0 see-details-btn"
                  v-bind="attrs"
                  v-on="on"
                  >See Details</v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog3 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="rounded-0">Comment</v-card-title>

                  <ol style="list-style-type: none">
                    <li>
                      Payments from third parties are not accepted. The name of
                      the sender must match the name on your trading account.
                    </li>
                  </ol>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <v-card-title class="d-block">Status</v-card-title>
            <v-card-subtitle class="approved-text">Approved</v-card-subtitle>
          </v-col>
          <v-col cols="1">
            <v-dialog max-width="554" v-model="dialog4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="icon-btn"
                  ><v-icon class="teal-text-lighten-1"
                    >mdi mdi-file-image-outline</v-icon
                  ></v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0 bg-transparent"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog4 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    class="white--text align-end"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ></v-img>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        class="listing-upload"
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4">
            <v-card-title>Date & Time</v-card-title>
            <v-card-subtitle>10/12-10:22pm</v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-card-title class="comment-title">Comment</v-card-title>
            <v-dialog
              transition="scale-transition"
              origin="center center"
              max-width="554"
              content-class="deposit-withdraw-popup"
              v-model="dialog5"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="text-capitalize pa-0 see-details-btn"
                  v-bind="attrs"
                  v-on="on"
                  >See Details</v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog5 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="rounded-0">Comment</v-card-title>

                  <ol style="list-style-type: none">
                    <li>
                      Payments from third parties are not accepted. The name of
                      the sender must match the name on your trading account.
                    </li>
                  </ol>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <v-card-title class="d-block">Status</v-card-title>
            <v-card-subtitle class="approved-text">Approved</v-card-subtitle>
          </v-col>
          <v-col cols="1">
            <v-dialog max-width="554" v-model="dialog6">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="icon-btn"
                  ><v-icon class="teal-text-lighten-1"
                    >mdi mdi-file-image-outline</v-icon
                  ></v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0 bg-transparent"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog6 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    class="white--text align-end"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ></v-img>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12">
      <v-card
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        class="listing-upload"
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4">
            <v-card-title>Date & Time</v-card-title>
            <v-card-subtitle>10/12-10:22pm</v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-card-title class="comment-title">Comment</v-card-title>
            <v-dialog
              transition="scale-transition"
              origin="center center"
              max-width="554"
              content-class="deposit-withdraw-popup"
              v-model="dialog7"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="text-capitalize pa-0 see-details-btn"
                  v-bind="attrs"
                  v-on="on"
                  >See Details</v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog7 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="rounded-0">Comment</v-card-title>

                  <ol style="list-style-type: none">
                    <li>
                      Payments from third parties are not accepted. The name of
                      the sender must match the name on your trading account.
                    </li>
                  </ol>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <v-card-title class="d-block">Status</v-card-title>
            <v-card-subtitle class="approved-text">Approved</v-card-subtitle>
          </v-col>
          <v-col cols="1">
            <v-dialog max-width="554" v-model="dialog8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="icon-btn"
                  ><v-icon class="teal-text-lighten-1"
                    >mdi mdi-file-image-outline</v-icon
                  ></v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0 bg-transparent"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog8 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    class="white--text align-end"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ></v-img>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        class="listing-upload"
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4">
            <v-card-title>Date & Time</v-card-title>
            <v-card-subtitle>10/12-10:22pm</v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-card-title class="comment-title">Comment</v-card-title>
            <v-dialog
              transition="scale-transition"
              origin="center center"
              max-width="554"
              content-class="deposit-withdraw-popup"
              v-model="dialog9"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="text-capitalize pa-0 see-details-btn"
                  v-bind="attrs"
                  v-on="on"
                  >See Details</v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog9 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="rounded-0">Comment</v-card-title>

                  <ol style="list-style-type: none">
                    <li>
                      Payments from third parties are not accepted. The name of
                      the sender must match the name on your trading account.
                    </li>
                  </ol>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <v-card-title class="d-block">Status</v-card-title>
            <v-card-subtitle class="approved-text">Approved</v-card-subtitle>
          </v-col>
          <v-col cols="1">
            <v-dialog max-width="554" v-model="dialog10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="icon-btn"
                  ><v-icon class="teal-text-lighten-1"
                    >mdi mdi-file-image-outline</v-icon
                  ></v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0 bg-transparent"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog10 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    class="white--text align-end"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ></v-img>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        class="listing-upload"
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4">
            <v-card-title>Date & Time</v-card-title>
            <v-card-subtitle>10/12-10:22pm</v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-card-title class="comment-title">Comment</v-card-title>
            <v-dialog
              transition="scale-transition"
              origin="center center"
              max-width="554"
              content-class="deposit-withdraw-popup"
              v-model="dialog11"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="text-capitalize pa-0 see-details-btn"
                  v-bind="attrs"
                  v-on="on"
                  >See Details</v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog11 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="rounded-0">Comment</v-card-title>

                  <ol style="list-style-type: none">
                    <li>
                      Payments from third parties are not accepted. The name of
                      the sender must match the name on your trading account.
                    </li>
                  </ol>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <v-card-title class="d-block">Status</v-card-title>
            <v-card-subtitle class="approved-text">Approved</v-card-subtitle>
          </v-col>
          <v-col cols="1">
            <v-dialog max-width="554" v-model="dialog12">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="icon-btn"
                  ><v-icon class="teal-text-lighten-1"
                    >mdi mdi-file-image-outline</v-icon
                  ></v-btn
                >
              </template>
              <template>
                <v-card
                  class="rounded-0 bg-transparent"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
                >
                  <v-btn
                    icon
                    
                    @click="dialog12 = false"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 1;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    class="white--text align-end"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  ></v-img>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      dialog7: false,
      dialog8: false,
      dialog9: false,
      dialog10: false,
      dialog11: false,
      dialog12: false,
    };
  },
};
</script>
