<template>
  <v-row dense>
    <v-col lg="6" md="6" sm="6" cols="12" v-for="card in cards" :key="card.id">
      <v-card
        class="verification-card"
        flat
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
      >
        <v-card-actions class="justify-space-between px-0 flex-wrap">
          <v-card-title class="pl-1">
            <v-icon class="pr-5" color="ash-lighten-4">{{ card.icon }}</v-icon
            >{{ card.title }}
          </v-card-title>
          <span class="edit">{{ card.subTitle }}</span>
        </v-card-actions>

        <v-card-subtitle class="text-center">
          <span class="d-block">{{ card.text1 }}</span>
          {{ card.text2 }}
        </v-card-subtitle>
        <v-card-text class="text-center">
          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
          ></vue-dropzone>
          <div class="dropzone-content">
            <v-icon large class="mb-3" color="ash-lighten-4"
              >{{ card.dropIcon }}</v-icon
            >
            <p class="mb-0">{{ card.para1 }} <strong>{{ card.para2 }}</strong></p>
            <span>{{ card.para3 }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "dropzoneView",
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 100,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      cards: [
        {
          id: 1,
          icon: "mdi mdi-account-outline",
          title: "Identity Verification",
          subTitle: "Go Client Portal to Edit",
          text1: "Upload: local passport (ID, IC)",
          text2: "or international passport or driver's license.",
          dropIcon: "mdi mdi-tray-arrow-up",
          para1: "Drop File Here or",
          para2: "Browse",
          para3: "File size max 2MB",
        },
        {
          id: 2,
          icon: "mdi mdi-home-lightning-bolt-outline",
          title: "Residence Verification",
          subTitle: "Go Client Portal to Edit",
          text1: "Upload: local passport (ID, IC)",
          text2: "or international passport or driver's license.",
          dropIcon: "mdi mdi-tray-arrow-up",
          para1: "Drop File Here or",
          para2: "Browse",
          para3: "File size max 2MB",
        }
      ],
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
};
</script>
