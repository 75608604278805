<template>
  <v-app :dark="!!(isDark === 'dark')" id="inspire" class="wrapper">
    
    <!----------------------Navbar Start ---------------------->
    <v-app-bar
      app
      elevate-on-scroll
      elevation="0"
      class="custom-navbar"
    >
      <v-container class="fill-height app-bar-container">
        <!-- Collapse Icon For Mobile Menu -->
        <v-app-bar-nav-icon
          class="d-md-none d-sm-flex d-xl-none d-lg-none"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <DashboardView></DashboardView>

        <v-spacer></v-spacer>

        <SignalsView></SignalsView>

        <LanguageMenu></LanguageMenu>

        <SwitcherView></SwitcherView>

        <NotificationView></NotificationView>

        <LogoutView></LogoutView>
      </v-container>
      
    </v-app-bar>
    <!----------------------Navbar End ---------------------->


    <!----------------------Sidebar & Main Body Start ---------------------->
    <v-container class="custom-container">
      <v-row no-gutters>
        <v-col xl="2" class="custom-width-navigation-drawer">
          <div class="navigation-drawer-container">
            <!-- Sidebar Start -->
            <v-navigation-drawer
              v-model="drawer"
              :permanent="$vuetify.breakpoint.mdAndUp"
              width="200"
              class="navigation-drawer sidebar-navigation-drawer"
              :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-2'"
            >
              <AdminLogo></AdminLogo>
              <AllMenu></AllMenu>
            </v-navigation-drawer>
            <!-- Sidebar End -->
          </div>
        </v-col>
        <v-col xl="10" class="custom-width-main">
          <!-- Main Section Start -->
          <v-main>
            <router-view></router-view>
          </v-main>
          <!-- Main Section End -->
        </v-col>
      </v-row>
    </v-container>
    <!----------------------Sidebar & Main Body End ---------------------->
  </v-app>
</template>

<script>
import AdminLogo from "./components/SidebarTopbar/AdminLogo.vue";
import AllMenu from "./components/SidebarTopbar/AllMenu.vue";
import DashboardView from "./components/SidebarTopbar/DashboardView.vue";
import LanguageMenu from "./components/SidebarTopbar/LanguageMenu.vue";
import LogoutView from "./components/SidebarTopbar/LogoutView.vue";
import NotificationView from "./components/SidebarTopbar/NotificationView.vue";
import SignalsView from "./components/SidebarTopbar/SignalsView.vue";
import SwitcherView from "./components/SidebarTopbar/SwitcherView.vue";
export default {
  data: () => ({
    drawer: null,
  }),
  components: {
    LanguageMenu,
    NotificationView,
    SignalsView,
    SwitcherView,
    DashboardView,
    LogoutView,
    AdminLogo,
    AllMenu,
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
