import YourAgreement from "@/views/Agreement/YourAgreement.vue";
import CommissionStatistics from "@/views/Commission/CommissionStatistics.vue";
import DashboardView from "@/views/Dashboard/DashboardView.vue";
import DroneView from "@/views/Drone/DroneView.vue";
import FaqView from "@/views/Faq/FaqView.vue";
import MarketingTools from "@/views/Marketing/MarketingTools.vue";
import AllTradingAccounts from "@/views/MyClients/AllTradingAccounts.vue";
import ClientTransactionHistory from "@/views/MyClients/ClientTransactionHistory.vue";
import ClientTreeLevelFive from "@/views/MyClients/ClientTreeLevelFive.vue";
import ClientTreeLevelFour from "@/views/MyClients/ClientTreeLevelFour.vue";
import ClientTreeLevelThree from "@/views/MyClients/ClientTreeLevelThree.vue";
import ClientTreeLevelTwo from "@/views/MyClients/ClientTreeLevelTwo.vue";
import MyClients from "@/views/MyClients/MyClients.vue";
import TradingHistory from "@/views/MyClients/TradingHistory.vue";
import ChangePassword from "@/views/Profile/ChangePassword.vue";
import OtpConfirm from "@/views/Profile/OtpConfirm.vue";
import ProfileView from "@/views/Profile/ProfileView.vue";
import TransactionHistory from "@/views/Transactions/TransactionHistory.vue";
import TransferForm from "@/views/Transactions/TransferForm.vue";
import WithdrawDetails from "@/views/Transactions/WithdrawDetails.vue";
import Vue from 'vue';
import VueRouter from 'vue-router';



Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/transaction-history",
    name: "TransactionHistory",
    component: TransactionHistory,
  },
  {
    path: "/withdraw",
    name: "WithdrawDetails",
    component: WithdrawDetails,
  },
  {
    path: "/otp-confirm",
    name: "OtpConfirm",
    component: OtpConfirm,
  },
  {
    path: "/transfer-form",
    name: "TransferForm",
    component: TransferForm,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/profile-view",
    name: "ProfileView",
    component: ProfileView,
  },
  {
    path: "/faq",
    name: "FaqView",
    component: FaqView,
  },
  {
    path: "/my-clients",
    name: "MyClients",
    component: MyClients,
  },
  {
    path: "/client-tree-level-two",
    name: "ClientTreeLevelTwo",
    component: ClientTreeLevelTwo,
  },
  {
    path: "/client-tree-level-three",
    name: "ClientTreeLevelThree",
    component: ClientTreeLevelThree,
  },
  {
    path: "/client-tree-level-four",
    name: "ClientTreeLevelFour",
    component: ClientTreeLevelFour,
  },
  {
    path: "/client-tree-level-five",
    name: "ClientTreeLevelFive",
    component: ClientTreeLevelFive,
  },
  {
    path: "/all-trading-accounts",
    name: "AllTradingAccounts",
    component: AllTradingAccounts,
  },
  {
    path: "/client-transaction-history",
    name: "ClientTransactionHistory",
    component: ClientTransactionHistory,
  },
  {
    path: "/trading-history",
    name: "TradingHistory",
    component: TradingHistory,
  },
  {
    path: "/commission-statistics",
    name: "CommissionStatistics",
    component: CommissionStatistics,
  },
  {
    path: "/your-agreement",
    name: "YourAgreement",
    component: YourAgreement,
  },
  {
    path: "/marketing-tools",
    name: "MarketingTools",
    component: MarketingTools,
  },
  {
    path: "/drone-view",
    name: "DroneView",
    component: DroneView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
