<template>
  <v-row class="no-gutters">
    <v-col cols="12" sm="12" md="4" lg="10">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Search anything here ..."
        single-line
        solo
        hide-details
        class="mt-0"
      ></v-text-field>
    </v-col>

    <v-col cols="6" sm="5" md="3" lg="2">
      <v-select
        solo
        :items="slots"
        append-icon="mdi-chevron-down"
        label="All Level"
        class="custom-select mr-0 mt-0"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      slots: ["Level-1", "Level-2", "Level-3"],
    };
  },
};
</script>
