<template>
  <v-container class="main-container responsive-height">
    <v-row dense>
      <v-col cols="12" xl="12">
        <v-sheet
          :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
          class="agreement"
        >
          <ul>
            <li :class="card.className" v-for="card in cards" :key="card.id">
              <div class="content">
                <div class="pdf-image">
                  <img
                    :src="card.image"
                    width="32"
                    height="39"
                    alt="pdf image"
                  />
                </div>
                <div class="image-details">
                  <h3>{{ card.title }}</h3>
                  <span>{{ card.subTitle }}</span>
                </div>
              </div>
              <div class="download-btn">
                <v-btn
                  tile
                  elevation="0"
                  class="text-capitalize btn-account"
                  color="teal-lighten-1"
                  >download</v-btn
                >
              </div>
            </li>
          </ul>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          id: 1,
          className: "pt-0",
          image:require('@/assets/pdf.png'),
          title:'Contact Information For Both Parties',
          subTitle:'17-11-2022',
        },
        {
          id: 2,
          className: "",
          image:require('@/assets/pdf.png'),
          title:'Contact Information For Both Parties',
          subTitle:'17-11-2022',
        },
        {
          id: 3,
          className: "",
          image:require('@/assets/pdf.png'),
          title:'Contact Information For Both Parties',
          subTitle:'17-11-2022',
        },
      ]
    };
  },
};
</script>
