<template>
  <form action="/action_page.php" class="transferForm">
    <v-container class="main-container responsive-height">
      <div class="to-trading-account">
        <v-row>
          <v-col xl="6" lg="6" md="5" sm="6" cols="12">
            <v-card
              class="deposit-form"
              :class="$vuetify.theme.dark ? 'blue-lighten-2' : 'white-lighten-5'"
            >
            <p class="mb-10">An OTP has sent to <span class="text--green">mkhassan25@gmail.com</span></p>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <fieldset>
                    <legend>Enter OTP</legend>
                    <v-text-field
                      label="Enter OTP here"
                      single-line
                      solo
                      hide-details
                      class="custom-select"
                    ></v-text-field>
                  </fieldset>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    tile
                    elevation="0"
                    type="submit"
                    class="ma-0 text-capitalize btn-account w-100"
                    color="teal-lighten-1"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      
    </v-container>
  </form>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
};
</script>

