<template>
    <v-dialog v-model="dialog" persistent max-width="400" class="rounded-0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :ripple="false" class="notification-btn" icon :class="$vuetify.theme.dark ? 'teal-text-lighten-1' : 'dark-text-lighten-1'">
          <v-icon v-bind="attrs" v-on="on">mdi-power</v-icon>
        </v-btn>
      </template>
      <v-card rounded="0" class="auth-modal-card" width="400" :class="$vuetify.theme.dark ? 'blue-darken-1' : 'white'">
        <v-card-title class="px-0 pt-0">
         Log Out ?
        </v-card-title>
        <v-card-text class="px-0"
          >Do You Want to log out from your trading CRM ?</v-card-text
        >
        <v-card-actions class="px-0 pb-0">
          <v-btn class="elevation-0" color="red-lighten-1"  @click="dialog = false">
            Log Out
          </v-btn>
          <v-btn class="elevation-0" outlined :class="$vuetify.theme.dark ? 'blue-darken-1' : ''" @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

