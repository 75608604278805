<template>
  <v-menu bottom left transition="slide-y-transition" class="signalMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :ripple="false"
        icon
        v-bind="attrs"
        v-on="on"
        class="notification-btn mr-sm-16 mr-0 text-capitalize"
      >
        <v-icon class="mr-2">mdi-chart-line</v-icon>
        <span class="d-none d-sm-flex">Signals</span>
        <v-icon class="d-none d-sm-flex">mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-card class="notification-card signals-card">
      <v-list
        class="pb-0"
        :class="$vuetify.theme.dark ? 'blue-darken-4 ' : 'white-lighten-2'"
      >
        <v-list-item>
          <v-list-item-content>
            <span class="title-text">Signals</span>
            <span>
              <v-icon color="teal"> mdi-circle-small </v-icon>Buy</span
            >
            <span>
              <v-icon color="error"> mdi-circle-small </v-icon>Sale</span
            >
          </v-list-item-content>
          <v-list-item-action>
            <router-link class="links" to="/all-signals">See All ></router-link>
          </v-list-item-action>
        </v-list-item>
        <template v-for="(item, index) in items">
          <v-list
            three-line
            :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white'"
            :key="item.id"
          >
            <ul class="chart-title pt-4">
              <li>
                <v-icon color="teal"> mdi-circle-small </v-icon>
                <h4>EUR USD</h4>
                <span> Sale</span>
              </li>
              <li>
                <p class="text-para">Target: <span>1.3892</span></p>
              </li>
            </ul>
            <div class="area-chart">
              <area-chart></area-chart>
            </div>
            <div class="trading-hour pb-9">
              <p class="text-para">Trading: <span>3h 15m</span></p>
              <p class="text-para">In: <span> 9h 32m</span></p>
            </div>
            <div class="user-info pb-10">
              <div class="user">
                <ul>
                  <li class="avatar-img">
                    <v-avatar size="15px" class="mr-1">
                      <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                      />
                    </v-avatar>
                    <p>Forex Bull <span>Copiers: 21</span></p>
                  </li>
                  <li><p class="text-para">Trading Days: 84</p></li>
                </ul>
              </div>
              <div class="user user-down">
                <ul>
                  <li class="mb-4 mt-1 text-right">
                    <p class="text-para">Profit: <span>91%</span></p>
                  </li>
                  <li>
                    <p class="text-para">Success: <span>78%</span></p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="buttons pb-6">
              <v-btn tile small class="btn-account" outlined color="teal-lighten-1">
                Copy
              </v-btn>
              <v-btn tile small class="btn-account" color="teal-lighten-1">
                Follow
              </v-btn>
            </div>
          </v-list>
          <v-divider
            v-if="index < items.length - 1"
            :key="index"
            class="custom-divider"
          ></v-divider>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import AreaChart from "./AreaChart.vue";
export default {
  data: () => ({
    items: [
      { id: 1, title: "" },
      { id: 2, title: "" },
    ],
  }),
  components: {
    AreaChart,
  },
};
</script>
