<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'">
    <FilterArea></FilterArea>
    <DataTableArea></DataTableArea>
  </v-card>
</template>
<script>
import DataTableArea from './DataTableArea.vue';
import FilterArea from './FilterArea.vue';
export default {
  components: { FilterArea, DataTableArea },
};
</script>
