<template>
  <v-row class="no-gutters">
    <v-col cols="12" sm="12" md="4" lg="5" class="mt-1 mt-md-0">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Search anything here ..."
        single-line
        solo
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="6" sm="5" md="3" lg="3">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
        class=""
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            prepend-icon="mdi-calendar"
            append-icon="mdi-chevron-down"
            readonly
            v-bind="attrs"
            v-on="on"
            solo
            class="custom-select"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          class="custom-date-picker"
          scrollable
          range
        >
          <v-spacer></v-spacer>
          <v-btn
            class="error-text-lighten-1"
            text
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            
            text
            @click="$refs.dialog.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col cols="6" sm="5" md="3" lg="3">
      <v-select
        solo
        :items="slots"
        append-icon="mdi-chevron-down"
        label="All Transaction Method"
        class="custom-select"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="2" md="2" lg="1">
      <ul>
        <li>
          <span>Balance:</span>
          <span>1500.50</span>
        </li>
      </ul>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      slots: ["7100001492(MICRO)", "7100006392(MICRO)", "7102006392(MICRO)"],

      date: ["2022-01-01", "2023-01-01"],
      modal: false,
    };
  },

  computed: {
    dateRangeText() {
      return this.date.join(" ~ ");
    },
  },
};
</script>
