<template>
  <v-row dense>
    <v-col lg="6" md="6" sm="6" cols="12" v-for="card in cards" :key="card.id">
      <v-card
        flat
        tile
        :color="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-1'"
        :class="card.cardClass"
      >
        <v-card-actions class="pl-5 align-start py-1">
          <v-icon class="mt-4 ash-darken-2" color="ash-lighten-4"> {{ card.iconClass }}</v-icon>
          <v-card-text class="px-0 py-0">
            <v-card-title class="text--green"
              >{{ card.title }}

              <v-tooltip top color="red-lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :class="card.toolTipClass"
                    small
                    class="ash-text-lighten-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi mdi-information-outline
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>{{ card.subTitle }}</v-card-subtitle>
          </v-card-text>
        </v-card-actions>
        <v-btn
          :class="card.className"
          tile
          link
          small
          to="/change-password"
          elevation="0"
          color="teal-lighten-1"
        >
          Change IB <span class="d-none d-md-block">Password</span>
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          id: 1,
          className: "d-none",
          cardClass: "my-profile",
          iconClass: "mdi mdi-account-check-outline ",
          toolTipClass: "d-none",
          title: "Profile Verified",
          subTitle: "Go Client Portal to Verify",
        },
        {
          id: 2,
          className: "mx-2 mb-3",
          cardClass: "d-flex align-center justify-space-between my-profile",
          iconClass: "mdi mdi-seal-variant",
          toolTipClass: "ml-2",
          title: "IB Activated",
          subTitle: "IB Status",
        },
      ],
    };
  },
};
</script>
