<template>
  <v-row dense>
    <v-col cols="12" sm="12">
      <v-card
        class="pa-0 rounded-0 elevation-0"
        :class="$vuetify.theme.dark ? 'blue-lighten-1 ' : 'white-lighten-1'"
      >
        <ul class="ma-0 pa-2 d-flex justify-space-between flex-wrap">
          <li class="d-flex flex-column" v-for="list in lists" :key="list.id">
            <span class="text--ash">{{ list.title }}</span>
            <span>{{ list.amount }}</span>
          </li>
        </ul>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      lists: [
        { id: 1, title: "Total Deposit", amount: 150000 },
        { id: 2, title: "Total Withdraw", amount: 123456 },
        { id: 3, title: "Net Deposit", amount: 153498 },
        { id: 4, title: "Total Profit", amount: 1392764 },
        { id: 5, title: "Total Size", amount: 1500 },
        { id: 6, title: "Total Accounts", amount: 900 },
        { id: 7, title: "Total Clients", amount: 1234 },
      ],
    };
  },
};
</script>
