<template>
  <v-row dense>
    <v-col cols="12" xl="12">
      <v-card
        elevation="0"
        class="all-trade-tab rounded-0 mb-0"
        :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
      >
        <div class="mt-0 px-4 pt-8 pb-3">
          <div class="titleName d-flex align-center">
            <v-img
              class="mr-2"
              max-width="17"
              src="@/assets/Dashboard/bar.svg"
            ></v-img>
            <span>Your Childs Level</span>
          </div>
        </div>
        <v-tabs
          :background-color="
            $vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'
          "
          class="pb-0"
        >
          <v-tab class="ml-0"> Level 2 </v-tab>
          <v-tab> Level 3 </v-tab>
          <v-tab> Level 4 </v-tab>
          <v-tab> Level 5 </v-tab>
          <v-tab> Level Earning Chart </v-tab>
          <v-tab-item>
            <v-card flat>
              <LevelTwoTab></LevelTwoTab>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <LevelThreeTab></LevelThreeTab>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <LevelFourTab></LevelFourTab>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <LevelFiveTab></LevelFiveTab>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <SummaryChartTab></SummaryChartTab>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LevelFiveTab from "../ClientsTreeAndEarning/LevelFiveTab.vue";
import LevelFourTab from "../ClientsTreeAndEarning/LevelFourTab.vue";
import LevelThreeTab from "../ClientsTreeAndEarning/LevelThreeTab.vue";
import LevelTwoTab from "../ClientsTreeAndEarning/LevelTwoTab.vue";
import SummaryChartTab from "../ClientsTreeAndEarning/SummaryChartTab.vue";

export default {
  components: {
    LevelTwoTab,
    LevelThreeTab,
    LevelFourTab,
    LevelFiveTab,
    SummaryChartTab,
  },
};
</script>

