<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'">
    <v-row class="pt-5">
      <v-col cols="12" lg="7" class="pb-0">
        <UploadedBanner></UploadedBanner>
      </v-col>
      <v-col cols="12" lg="5" class="pb-0">
        <BannerSize></BannerSize>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import BannerSize from './BannerSize.vue';
import UploadedBanner from './UploadedBanner.vue';
export default {
  components: { UploadedBanner, BannerSize, },
};
</script>
