import { render, staticRenderFns } from "./StaticBanner.vue?vue&type=template&id=58da7a84"
import script from "./StaticBanner.vue?vue&type=script&lang=js"
export * from "./StaticBanner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports