<template>
  <div class="card-wrapper-box">
    <v-card
      class="elevation-0 rounded-0 deposit-form-card"
      :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-1'"
    >
      <div class="ribbon"><span>To</span></div>
      <v-card-actions>
        <div class="trade-status">
          <div>
            <v-icon class="teal-text-lighten-1">mdi-signal</v-icon>
          </div>
          <div class="trade-number">
            <span>MT5 - Real</span>
            <span>10025987</span>
          </div>
        </div>
      </v-card-actions>
      <v-card-actions>
        <div class="value-trades">
          <span>Balance</span>
          <span>500.00</span>
        </div>
        <v-spacer></v-spacer>
        <div class="value-trades">
          <span>Currency</span>
          <span>USD</span>
        </div>
      </v-card-actions>
      <v-card-actions>
        <v-sheet :class="$vuetify.theme.dark ? 'transparent ' : 'transparent'">
          <div>
            <apexChart
              width="100%"
              height="95px"
              type="line"
              :options="options"
              :series="series"
            >
            </apexChart>
          </div>
        </v-sheet>
      </v-card-actions>
      <v-card-actions>
        <div class="currency-leverage">
          <span>Free Funds</span>
          <span>5020.00</span>
        </div>
        <v-spacer></v-spacer>
        <div class="currency-leverage">
          <span>Leverage</span>
          <span>500:1</span>
        </div>
      </v-card-actions>
      <v-card-actions>
        <div class="balance-equity">
          <span>Free Margin</span>
          <span>500.00</span>
        </div>
        <v-spacer></v-spacer>
        <div class="balance-equity">
          <span>Acc. Type</span>
          <span>Test</span>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#3A648E"],
      },
      series: [
        {
          name: "Series 1",
          data: [45, 52, 38, 45, 19, 23, 2],
        },
      ],
    };
  },
};
</script>
