<template>
  <v-container class="main-container responsive-height">
    <v-row dense> 
      <v-col cols="12" xl="12">
        <v-sheet
          :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
          class="agreement"
        >
          <template>
            <div>
              <div id="tree-container"></div>
            </div>
          </template>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as d3 from 'd3';

export default {
  mounted() {
    this.drawTree();
  },
  methods: {
    drawTree() {
      // Dummy data representing a tree structure
      const treeData = {
        name: 'Root',
        children: [
          {
            name: 'Node 1',
            children: [
              { name: 'Leaf 1.1' },
              { name: 'Leaf 1.2' }
            ]
          },
          {
            name: 'Node 2',
            children: [
              { name: 'Leaf 2.1' },
              { name: 'Leaf 2.2' }
            ]
          }
        ]
      };

      const width = 500;
      const height = 300;

      const svg = d3.select('#tree-container')
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', 'translate(50, 50)');

      const treeLayout = d3.tree().size([width - 100, height - 100]);

      const rootNode = d3.hierarchy(treeData);

      treeLayout(rootNode);

      const link = svg.selectAll('.link')
        .data(rootNode.links())
        .enter().append('path')
        .attr('class', 'link')
        .attr('d', d3.linkHorizontal()
          .x(d => d.y)
          .y(d => d.x));

      const node = svg.selectAll('.node')
        .data(rootNode.descendants())
        .enter().append('g')
        .attr('class', 'node')
        .attr('transform', d => `translate(${d.y},${d.x})`)
        .call(d3.drag()
          .on('start', this.dragStarted)
          .on('drag', this.dragged)
          .on('end', this.dragEnded));

      node.append('circle')
        .attr('r', 7)
        .on('click', this.toggleChildren);

      node.append('text')
        .attr('dy', '.31em')
        .attr('x', d => (d.children ? -13 : 13))
        .style('text-anchor', d => (d.children ? 'end' : 'start'))
        .text(d => d.data.name);
    },
    toggleChildren(d) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      this.drawTree(); // Redraw the tree after toggling
    },
    dragStarted(event, d) {
      if (!event.active) d3.select(this).raise().classed('active', true);
    },
    dragged(event, d) {
      d.x0 += event.dy;
      d.y0 += event.dx;
      d3.select(this).attr('transform', `translate(${d.y0},${d.x0})`);
      d3.select(this).selectAll('path.link').attr('d', d3.linkHorizontal()
        .x(d => d.y0)
        .y(d => d.x0));
    },
    dragEnded(event, d) {
      if (!event.active) d3.select(this).classed('active', false);
    }
  }
};
</script>
