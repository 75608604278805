<template>
  <v-stepper v-model="e1" class="password-stepper">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" color="teal-lighten-1">
        Step-1
      </v-stepper-step>

      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 2" step="2" color="teal-lighten-1">
        Step-2
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" color="#708AA7"> Password </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="deposit-form create-account-form"
          :class="$vuetify.theme.dark ? 'transparent' : 'transparent'"
        >
          <v-row dense>
            <v-col cols="12" sm="12" md="12" :class="stepOne.className" v-for="stepOne in stepOnes" :key="stepOne.id">
              <fieldset>
                <legend>{{ stepOne.legend }}</legend>
                <v-text-field
                  :label="stepOne.label"
                  single-line
                  solo
                  hide-details
                  class="custom-select"
                ></v-text-field>
                <p :class="stepOne.errorClass">{{ stepOne.errorText }}</p>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                class="text-capitalize btn-account"
                color="teal-lighten-1"
                tile
                elevation="0"
                @click="e1 = 2"
              >
                Next Step
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card
          class="deposit-form create-account-form"
          color="transparent"
        >
          <v-row dense>
            <v-col cols="12" sm="12" md="12" v-for="stepTwo in stepTwos" :key="stepTwo.id">
              <fieldset>
                <legend>{{ stepTwo.legend }}</legend>
                <v-text-field
                  :label="stepTwo.label"
                  single-line
                  solo
                  hide-details
                  class="custom-select"
                ></v-text-field>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                class="text-capitalize btn-account"
                
                color="teal-lighten-1"
                tile
                elevation="0"
                @click="e1 = 3"
              >
                Next Step
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="deposit-form password-field create-account-form"
          color="transparent"
        >
          <v-row>
            <v-col>
              <p>
                An OTP has send to <span class="text--green">mkhassan25@gmail.com</span>
              </p>
              <fieldset>
                <legend>Enter Password</legend>
                <v-text-field
                  label="**************"
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  counter
                  @click:append="show1 = !show1"
                  single-line
                  solo
                  hide-details
                  class="custom-select"
                ></v-text-field>
              </fieldset>
              
              <v-card-actions class="pa-0">
                <v-btn
                  class="ma-0 text-capitalize btn-account w-100"
                  color="teal-lighten-1"
                  tile
                  elevation="0"
                  @click="e1 = 3"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
export default {
  data() {
    return {
      stepOnes: [
        {
          id: 1,
          className: "mb-5",
          legend: "Withdraw Amount",
          label: "Enter Your Amount",
          errorText: "*Your balance is not sufficient for this withdrawal request",
          errorClass: "error-text mt-4",
        },
        {
          id: 2,
          className: "",
          legend: "Bank Name",
          label: "Write Bank Name",
          errorText: "",
          errorClass: "d-none",
        },
        {
          id: 3,
          className: "",
          legend: "Bank Country",
          label: "Write Country Name",
          errorText: "",
          errorClass: "d-none",
        },
        {
          id: 4,
          className: "",
          legend: "Bank Account Name",
          label: "Write Account Name",
          errorText: "",
          errorClass: "d-none",
        },
      ],
      stepTwos: [
        {
          id: 1,
          legend: "IBAN Number",
          label: "Enter Your Name",
        },
        {
          id: 2,
          legend: "Bank Swift Code",
          label: "Write Bank Name",
        },
        {
          id: 3,
          legend: "Bank Address",
          label: "Write Country Name",
        },
        {
          id: 4,
          legend: "Correspondent Bank Name",
          label: "Write Here",
        },
      ],
      
      show1: false,
      show2: false,
      password: "",
      password2: "",
      e1: 1,
    };
  },
};
</script>
