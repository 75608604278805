<template>
  <v-sheet :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'">
    <v-container class="main-container responsive-height">
      <v-row dense>
        <v-col cols="12">
          <div class="titleName mt-5 mb-7 d-flex align-center">
            <v-img class="mr-3" max-width="17" src="@/assets/Dashboard/bar.svg"></v-img>
            <span>Transaction History</span>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" xl="12">
          <v-card elevation="0" class="all-trade-tab rounded-0 ma-0" :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'">
            <v-tabs
              :background-color="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'"
              class="pa-0"
            >
              <v-tab class="ml-0"> All Level </v-tab>
              <v-tab> Level 1 </v-tab>
              <v-tab> Level 2 </v-tab>
              <v-tab> Level 3 </v-tab>
              <v-tab> Level 4 </v-tab>
              <v-tab> Level 5 </v-tab>

              <v-tab-item>
                <v-card flat>
                  <ClientsAllLevel></ClientsAllLevel>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <ClientsLevelOne></ClientsLevelOne>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <ClientsLevelTwo></ClientsLevelTwo>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <ClientsLevelThree></ClientsLevelThree>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <ClientsLevelFour></ClientsLevelFour>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <ClientsLevelFive></ClientsLevelFive>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import ClientsAllLevel from "@/components/MyClients/ClientTransactionHistory/ClientsAllLevel.vue";
import ClientsLevelFive from "@/components/MyClients/ClientTransactionHistory/ClientsLevelFive.vue";
import ClientsLevelFour from "@/components/MyClients/ClientTransactionHistory/ClientsLevelFour.vue";
import ClientsLevelOne from "@/components/MyClients/ClientTransactionHistory/ClientsLevelOne.vue";
import ClientsLevelThree from "@/components/MyClients/ClientTransactionHistory/ClientsLevelThree.vue";
import ClientsLevelTwo from "@/components/MyClients/ClientTransactionHistory/ClientsLevelTwo.vue";

export default {
  components: {
    ClientsAllLevel,
    ClientsLevelOne,
    ClientsLevelTwo,
    ClientsLevelThree,
    ClientsLevelFour,
    ClientsLevelFive,
  },
};
</script>
