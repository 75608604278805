<template>
  <v-list>
    <v-list-group
      v-for="item in items"
      :key="item.title"
      v-model="item.active"
      action
      link
      :prepend-icon="item.action"
    >
      <template v-slot:activator>
        <v-list-item-content >
          <router-link
            v-text="item.title"
            class="text-decoration-none"
            router
            :to="item.link"
          ></router-link>
        </v-list-item-content>
      </template>
      <v-list-item-group>
        <v-list-item v-for="child in item.items" :key="child.title" link>
          <v-list-item-content >
            <router-link
              v-text="child.title"
              class="text-decoration-none"
              router
              :to="child.childLink"
            ></router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        action: "mdi-view-dashboard",
        title: "Dashboard",
        link: "/",
        active: true,
      },
      {
        action: "mdi mdi-credit-card-outline",
        title: "Transactions",
        items: [
          {
            title: "Trx. History",
            childLink: "/transaction-history",
          },
          { title: "Transfer Balance", childLink: "/transfer-form" },
          { title: "Withdraw Balance", childLink: "/withdraw" },
          
        ],
        link: "",
      },
      {
        action: "mdi-account-outline",
        items: [
          {
            title: "View Profile",
            childLink: "/profile-view",
          },
          { title: "Change Password", childLink: "/change-password" },
          
        ],
        title: "Profile",
        link: "",
      },
      {
        action: "mdi mdi-chart-arc",
        title: "Commission Sats",
        link: "/commission-statistics",
      },
      {
        action: "mdi mdi-account-multiple-outline",
        items: [
          { title: "Clients Tree & Earning", childLink: "/my-clients" },
          { title: "Transactions", childLink: "/client-transaction-history" },
          { title: "All Trading Accounts", childLink: "/all-trading-accounts" },
          { title: "Trading History", childLink: "/trading-history" },
        ],
        title: "Clients",
        link: "",
      },
      {
        action: "mdi mdi-quadcopter",
        title: "Drone View",
        link: "/drone-view",
      },
      {
        action: "mdi mdi-television",
        title: "Marketing Tools",
        link: "/marketing-tools",
      },
      {
        action: "mdi mdi-file-chart-outline",
        title: "Your Agreement",
        link: "/your-agreement",
      },
      {
        action: "mdi-clock-time-twelve-outline",
        title: "FAQ",
        link: "/faq",
      },
      
    ],
  }),
};
</script>
