<template>
  <v-menu bottom left transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :ripple="false"
        icon
        v-bind="attrs"
        v-on="on"
        class="notification-btn"
        :class="$vuetify.theme.dark ? 'teal-text-lighten-1' : 'dark-text-lighten-1'"
      >
        <v-icon>mdi-bell-outline</v-icon>
        <v-badge color="red-lighten-1" content="10" overlap> </v-badge>
      </v-btn>
    </template>

    <v-card class="notification-card">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Notification</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <span> <v-icon color="ash-lighten-5"> mdi-circle-small </v-icon>Read</span>
            <span> <v-icon color="teal"> mdi-circle-small </v-icon>Unread</span>
          </v-list-item-action>
        </v-list-item>
        <v-list three-line :class="$vuetify.theme.dark ? '$blue-darken-1 ' : 'white'">
          <v-list-item-group v-model="selected" multiple>
            <template v-for="(item, index) in items">
              <v-list-item :key="item.title">
                <template v-slot:default="{ active }">
                  <v-list-item-icon>
                    <v-icon v-if="!active" color="ash-lighten-5">mdi-circle-small</v-icon>
                    <v-icon v-else color="teal"> mdi-circle-small </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.headline"></v-list-item-subtitle>
                    <v-list-item-action-text
                      v-text="item.subtitle"
                    ></v-list-item-action-text>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-list>

      
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    selected: [1],
    items: [
      {
        action: "",
        headline: "1 minute ago",
        subtitle: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies quis tempus laoreet quisque volutpat.`,
        title: "New signal news, Get 100% Growth",
      },
      {
        action: "",
        headline: "10 minute ago",
        subtitle: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies quis tempus laoreet quisque volutpat.`,
        title: "$15 Deposit Approved",
      },
      {
        action: "",
        headline: "1 hour ago",
        subtitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies quis tempus laoreet quisque volutpat.",
        title: "$105 Widthdraw Approved",
      },
    ],
  }),
};
</script>
