<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'">
    <FilteringBar></FilteringBar>
    <FilteringTable></FilteringTable>
  </v-card>
</template>
<script>
import FilteringBar from './FilteringBar.vue';
import FilteringTable from './FilteringTable.vue';
export default {
  components: { FilteringBar, FilteringTable },
};
</script>
