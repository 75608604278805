<template>
  <v-row dense>
    <v-col cols="12">
      <v-sheet
        class="transactionTable pb-0"
        :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-2'"
      >
        <div class="title-bar">
          <div class="d-flex align-center">
            <v-img class="mr-3" max-width="17" src="@/assets/Dashboard/bar.svg"></v-img>
            <v-card-title class="pa-0">IB Commission In</v-card-title>
          </div>
          <v-spacer></v-spacer>
          <router-link class="links pr-0" to="/transaction-history"
            >See All Transactions ></router-link
          >
        </div>
        <v-data-table
          :headers="headers"
          hide-default-footer
          :items="Transaction"
        ></v-data-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Update Time", align: "start", sortable: false, value: "time" },
        { text: "Name", value: "name" },
        { text: "IB Code", value: "ibCode" },
        { text: "Account No", value: "accountNum" },
        { text: "Country", value: "country" },
        { text: "Client Level", value: "clientLevel" },
        { text: "Commission ($)", value: "commission" },
      ],
      Transaction: [
        {
          time: "10.02.22-08.22 pm",
          name: "Salauddin Ayubi",
          ibCode: "34355344344",
          accountNum: "34355344344",
          country: "Afganistan",
          clientLevel: "Level 1",
          commission: "12",
        },
        {
          time: "10.02.22-08.22 pm",
          name: "Khalid Bin Walid",
          ibCode: "34355344344",
          accountNum: "34355344344",
          country: "Kazakistan",
          clientLevel: "Level 3",
          commission: "12",
        },
        {
          time: "10.02.22-08.22 pm",
          name: "Rukunuddin Baibars",
          ibCode: "34355344344",
          accountNum: "34355344344",
          country: "Turkimenstan",
          clientLevel: "Level 2",
          commission: "12",
        },
      ],
    };
  },
};
</script>
