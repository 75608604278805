<template>
  <v-card
    class="deposit-form"
    :class="$vuetify.theme.dark ? 'blue-lighten-2' : 'white-lighten-5'"
  >
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <fieldset>
          <legend>New Partner Password</legend>
          <v-text-field
            label="**************"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            counter
            @click:append="show1 = !show1"
            single-line
            solo
            hide-details
            class="custom-select"
          ></v-text-field>
        </fieldset>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <fieldset>
          <legend>Confirm Password</legend>
          <v-text-field
            v-model="password2"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            counter
            @click:append="show2 = !show2"
            label="Write Password Again"
            single-line
            solo
            hide-details
            class="custom-select"
          ></v-text-field>
        </fieldset>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-btn
          tile
          elevation="0"
          to="/otp-confirm"
          class="ma-0 text-capitalize btn-account d-block"
          color="teal-lighten-1"
        >
          Confirm
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      show2: false,
      password: "",
      password2: "",
    };
  },
};
</script>
