<template> 
  <v-row dense>
    <v-col cols="12" sm="12">
      <v-card
        class="pa-0 rounded-0 elevation-0"
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
      >
        <v-sheet
          class="transactionTable ib-commission-table"
          :class="$vuetify.theme.dark ? 'blue-lighten-1 ' : 'white-lighten-1'"
        >
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="Transaction"
          >
            <template v-slot:item.detail="{ item }">
              <v-dialog
                transition="scale-transition"
                origin="center center"
                max-width="554"
                v-model="dialog"
                content-class="deposit-withdraw-popup"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mdi mdi-eye-outline text--ash"
                  >
                    {{ item.detail }}
                  </v-icon>
                </template>

                <v-card
                  class="rounded-0 px-0"
                  :class="$vuetify.theme.dark ? 'blue-darken-2' : 'secondary2'"
                >
                  <v-card-title class="rounded-0 pb-5"
                    >Client ID: 93999</v-card-title
                  >
                  <v-card-actions class="text-center flex-column">
                    <p
                      :class="clientInfo.ClassName"
                      v-for="clientInfo in clientInfos"
                      :key="clientInfo.id"
                    >
                      {{ clientInfo.title }}
                      <span class="text--ash">{{ clientInfo.subTitle }}</span>
                    </p>
                  </v-card-actions>
                  <ul class="ma-0 pa-0 py-10 px-0">
                    <li
                      class="d-flex justify-space-between px-sm-10 px-4"
                      v-for="list in lists"
                      :key="list.id"
                    >
                      <div class="infos py-3">
                        <span class="d-block text--ash mb-2">{{
                          list.title
                        }}</span>
                        <span class="d-block text--white">{{
                          list.subTitle
                        }}</span>
                      </div>
                      <div class="infos py-3">
                        <span class="d-block text--ash mb-2 text-right">{{
                          list.titleAnother
                        }}</span>
                        <span class="d-block text--white text-right">{{
                          list.subTitleAnother
                        }}</span>
                      </div>
                    </li>
                  </ul>
                  <v-btn
                    tile
                    outlined
                    @click="dialog = false"
                    elevation="0"
                    class="text-capitalize btn-account mx-auto d-flex px-16"
                    >Close</v-btn
                  >
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Client ID", align: "start", value: "clientId" },
        { text: "Trading Acc", value: "tradingAccount" },
        { text: "IB Code", value: "IbCode" },
        { text: "Email", value: "emailId" },
        { text: "Deposit", value: "depositAmount" },
        { text: "Withdraw", value: "withdrawAmount" },
        { text: "Profit", value: "profitAmount" },
        { text: "Commission", value: "commission" },
        { text: "Detail", value: "detail" },
      ],
      Transaction: [
        {
          clientId: "we23r323r",
          tradingAccount: "2323334434",
          IbCode: "2323334434",
          emailId: "bappymithun1234@gmail.com",
          depositAmount: "1200",
          withdrawAmount: "120",
          profitAmount: "120",
          commission: "120",
          detail: "",
        },
      ],
      dialog: false,
      clientInfos: [
        {
          id: 1,
          ClassName: "mb-1",
          title: "Name: ",
          subTitle: "John Doe",
        },
        {
          id: 2,
          ClassName: "mb-1",
          title: "Email: ",
          subTitle: "example@gmail.com",
        },
        {
          id: 3,
          ClassName: "mb-0",
          title: "IB Code: ",
          subTitle: "2342255",
        },
      ],
      lists: [
        {
          id: 1,
          title: "Client ID",
          subTitle: "29737",
          titleAnother: "IB Code",
          subTitleAnother: "29846",
        },
        {
          id: 2,
          title: "Account Number",
          subTitle: "29837",
          titleAnother: "Email Address",
          subTitleAnother: "example@gmail.com",
        },
        {
          id: 3,
          title: "Deposit",
          subTitle: "19372",
          titleAnother: "Withdraw",
          subTitleAnother: "9812",
        },
        {
          id: 4,
          title: "Net Deposit",
          subTitle: "3982",
          titleAnother: "Profit",
          subTitleAnother: "1093",
        },
        {
          id: 5,
          title: "Commission",
          subTitle: "398",
          titleAnother: "Total Earning",
          subTitleAnother: "3981",
        },
        {
          id: 6,
          title: "Size",
          subTitle: "4982",
          titleAnother: "Number Of Accounts",
          subTitleAnother: "31",
        },
      ],
    };
  },
};
</script>
