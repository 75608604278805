<template>
  <v-sheet :class="$vuetify.theme.dark ? 'blue-darken-2' : 'transparent'">
    <v-container class="main-container responsive-height">
      <v-row dense>
        <v-col cols="12">
          <div class="titleName mt-5 mb-7 d-flex align-center">
            <v-img
              class="mr-3"
              max-width="17"
              src="@/assets/Dashboard/bar.svg"
            ></v-img>
            <span>Transaction History</span>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" xl="12">
          <v-card elevation="0" class="all-trade-tab rounded-0 mb-0">
            <v-tabs
              :background-color="
                $vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'
              "
              class="pa-0"
            >
              <v-tab class="ml-0"> IB Commission In</v-tab>
              <v-tab> Withdraw </v-tab>
              <v-tab> Internal Trx. Out </v-tab>
              <v-tab-item>
                <v-card flat class="mb-0">
                  <IbCommissionInTab></IbCommissionInTab>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <WithdrawTab></WithdrawTab>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <InternalTransOutTab></InternalTransOutTab>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import IbCommissionInTab from "@/components/Transactions/IbCommissionInTab.vue";
import InternalTransOutTab from "@/components/Transactions/InternalTransOutTab.vue";
import WithdrawTab from "@/components/Transactions/WithdrawTab.vue";

export default {
  data: () => ({
    forecast: [
      { day: "Deposit", icon: "mdi-tray-arrow-down", links: "/deposit" },
      { day: "Withdraw", icon: "mdi-tray-arrow-up", links: "/withdraw" },
      {
        day: "Internal Trx.",
        icon: "mdi-swap-horizontal",
        links: "/transfer-form",
      },
    ],
  }),
  components: {
    WithdrawTab,
    IbCommissionInTab,
    InternalTransOutTab,
  },
};
</script>
