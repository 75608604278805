<template>
  <form action="/action_page.php" class="transferForm">
    <v-container class="main-container responsive-height">
      <div class="radio-buttons">
        <v-row class="mb-2">
          <v-col xl="3" lg="3" md="4" sm="4" cols="12">
            <label>
              <input type="radio" v-model="x" value="to-trading-account" />
              New Partner Password
            </label>
          </v-col>
          <v-col xl="9" lg="9" md="8" sm="8" cols="12">
            <label>
              <input type="radio" v-model="x" value="to-ib-account" /> Use
              Existing Client Password
            </label>
          </v-col>
        </v-row>
      </div>
      <div v-show="x === 'to-trading-account'" class="to-trading-account">
        <v-row>
          <v-col xl="6" lg="6" md="5" sm="6" cols="12">
            <PartnerPassword></PartnerPassword>
          </v-col>
        </v-row>
      </div>
      <div v-show="x === 'to-ib-account'" class="to-ib-account">
        <v-row>
          <v-col xl="6" lg="6" md="5" sm="6" cols="12">
            <ClientPassword></ClientPassword>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </form>
</template>

<script>
import ClientPassword from '@/components/Profile/ClientPassword.vue';
import PartnerPassword from '@/components/Profile/PartnerPassword.vue';
export default {
  components: { PartnerPassword, ClientPassword, },
  data() {
    return {
      x: "to-trading-account",
    };
  },
};
</script>
