<template>
  <v-sheet :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'transparent'">
    <v-container class="main-container responsive-height">
      <v-card
        elevation="0"
        class="border-0 rounded-0 live-trade-details-card"
        :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'"
      >
        <FilteringArea></FilteringArea>
        <InfoArea></InfoArea>
        <DataTableArea></DataTableArea>
      </v-card>
    </v-container>
  </v-sheet>
</template>

<script>
import DataTableArea from '@/components/MyClients/AllTradingAccounts/DataTableArea.vue';
import FilteringArea from '@/components/MyClients/AllTradingAccounts/FilteringArea.vue';
import InfoArea from '@/components/MyClients/AllTradingAccounts/InfoArea.vue';
export default {
  components: { FilteringArea, InfoArea, DataTableArea }
}
</script>
