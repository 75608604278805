<template>
  <v-sheet color="transparent">
    <div>
      <apexChart
        width="100%"
        height="100px"
        type="area"
        :options="options"
        :series="series"
      >
      </apexChart>
    </div>
  </v-sheet>
</template>
<script>
export default {
  name: "AreaChart",
  data: () => ({
    options: {
      chart: {
        height: 200,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#00897b"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          opacityFrom: 0.1,
          opacityTo: 0.1,
          stops: [0, 90, 100],
        },
      },
    },
    series: [
      {
        name: "Series 1",
        data: [45, 52, 38, 45, 19, 23, 2],
      },
    ],
  }),
};
</script>
