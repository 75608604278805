<template>
  <v-card :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-5'">
    <IbCommissionInTabForm></IbCommissionInTabForm>
    <IbCommissionInTabTable></IbCommissionInTabTable>
  </v-card>
</template>
<script>
import IbCommissionInTabForm from "./IbCommissionInTabForm.vue";
import IbCommissionInTabTable from "./IbCommissionInTabTable.vue";

export default {
  components: {
    IbCommissionInTabForm,
    IbCommissionInTabTable,
  },
};
</script>