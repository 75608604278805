<template>
  <v-row dense>
    <v-col cols="12" sm="12">
      <v-card
        class="pa-0 rounded-0 elevation-0"
        :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
      >
        <v-sheet
          class="transactionTable pb-0"
          :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white-lighten-1'"
        >
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            :items-per-page="10"
          >
            <template v-slot:item.amount="{ item }">
              <span :class="verifyIcon(item.amount)">{{
                item.amountNumber
              }}</span>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Client Name",
          align: "start",
          value: "clientName",
        },
        { text: "Country", value: "countryName" },
        { text: "IB Code", value: "ibCode" },
        { text: "Account No.", value: "accountNumber" },
        { text: "Trx Type", value: "trxType" },
        { text: "Amount", value: "amount" },
      ],
      desserts: [
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Withdraw",
          amount: "",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Deposit",
          amount: "deposit",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Withdraw",
          amount: "",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Deposit",
          amount: "deposit",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Withdraw",
          amount: "",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Deposit",
          amount: "deposit",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Withdraw",
          amount: "",
          amountNumber: 120,
        },
        {
          clientName: "Arafat Rahman",
          countryName: "India",
          ibCode: "1658839464",
          accountNumber: "1658839464",
          trxType: "Deposit",
          amount: "deposit",
          amountNumber: 120,
        },
      ],
    };
  },
  methods: {
    verifyIcon(amount) {
      if (amount === "deposit") return "text--green";
      else return "text-red";
    },
  },
};
</script>