<template>
  <v-container class="main-container responsive-height">
    <v-row>
      <v-col cols="12">
        <v-card
          class="elevation-0 rounded-0 faq-card pb-3"
          :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2 '"
        >
          <v-card-title>
            <v-icon class="mr-3" color="teal-lighten-1">mdi mdi-chat-outline</v-icon>
            Frequently Asked Questions
          </v-card-title>
          <v-expansion-panels
            v-model="panel"
            multiple
            flat
            tile
          >
            <v-expansion-panel v-for="item in items" :key="item.numbers">
              <v-expansion-panel-header
                :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white-lighten-1'"
              >
                <span class="numbers">{{ item.numbers }}</span
                >{{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
              >
                <p>{{ item.details }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    panel: [0],
    items: [
      {
        numbers: 1,
        title: "How Do I open a Partners Account?",
        details:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non cras tempus eget curabitur ac imperdiet mauris morbi. Facilisis lacus amet, duis aliquam. Egestas elementum enim quis duis pharetra bibendum orci hac id. Bibendum amet, elementum faucibus cursus. Enim libero faucibus in viverra aliquet. Vulputate mollis cras fringilla massa nullam sit euismod diam. In nulla velit commodo eget faucibus felis. Pulvinar enim arcu ut a amet vestibulum id. Nec fermentum pretium fusce vel lectus enim feugiat erat nisl. Viverra adipiscing in ipsum et senectus purus. Est tortor etiam arcu eget id gravida",
      },
      {
        numbers: 2,
        title: "How Do I open a Partners Account?",
        details:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non cras tempus eget curabitur ac imperdiet mauris morbi. Facilisis lacus amet, duis aliquam. Egestas elementum enim quis duis pharetra bibendum orci hac id. Bibendum amet, elementum faucibus cursus. Enim libero faucibus in viverra aliquet. Vulputate mollis cras fringilla massa nullam sit euismod diam. In nulla velit commodo eget faucibus felis. Pulvinar enim arcu ut a amet vestibulum id. Nec fermentum pretium fusce vel lectus enim feugiat erat nisl. Viverra adipiscing in ipsum et senectus purus. Est tortor etiam arcu eget id gravida",
      },
      {
        numbers: 3,
        title: "How Do I open a Partners Account?",
        details:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non cras tempus eget curabitur ac imperdiet mauris morbi. Facilisis lacus amet, duis aliquam. Egestas elementum enim quis duis pharetra bibendum orci hac id. Bibendum amet, elementum faucibus cursus. Enim libero faucibus in viverra aliquet. Vulputate mollis cras fringilla massa nullam sit euismod diam. In nulla velit commodo eget faucibus felis. Pulvinar enim arcu ut a amet vestibulum id. Nec fermentum pretium fusce vel lectus enim feugiat erat nisl. Viverra adipiscing in ipsum et senectus purus. Est tortor etiam arcu eget id gravida",
      },
      {
        numbers: 4,
        title: "How Do I open a Partners Account?",
        details:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non cras tempus eget curabitur ac imperdiet mauris morbi. Facilisis lacus amet, duis aliquam. Egestas elementum enim quis duis pharetra bibendum orci hac id. Bibendum amet, elementum faucibus cursus. Enim libero faucibus in viverra aliquet. Vulputate mollis cras fringilla massa nullam sit euismod diam. In nulla velit commodo eget faucibus felis. Pulvinar enim arcu ut a amet vestibulum id. Nec fermentum pretium fusce vel lectus enim feugiat erat nisl. Viverra adipiscing in ipsum et senectus purus. Est tortor etiam arcu eget id gravida",
      },
    ],
  }),
};
</script>
