<template>
  <v-card
    tile
    flat
    class="pb-5"
    :class="$vuetify.theme.dark ? 'blue-darken-2 ' : 'white-lighten-2'"
  >
    <v-card-title class="pb-3">
      <v-img
        class="mr-3"
        max-width="16"
        src="@/assets/Dashboard/dollar.svg"
      ></v-img>
      Currency Group Commission
    </v-card-title>
    <apexcharts
      type="donut"
      :width="288"
      :height="288"
      :options="chartOptions"
      :series="chartOptions.series"
    ></apexcharts>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts,
  },
  computed: {
    chartOptions: function () {
      return {
        colors: ["#10A689", "#7820E9", "#1489DF"],
        legend: {
          position: "bottom",
          fontSize: "10px",
          fontFamily: "Inter",
          fontWeight: 400,
          labels: {
            colors: "#708AA7",
          },
          markers: {
            width: 6,
            height: 6,
          },
          itemMargin: {
            horizontal: 5,
          },
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["ECN", "PRIMARY", "SECONDARY"],
        series: [300, 300, 300],
      };
    },
  },
};
</script>
