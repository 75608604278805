<template>
  <v-row dense>
      <v-col cols="12" sm="12">
        <v-card
          class="pa-0 rounded-0 elevation-0"
          :class="$vuetify.theme.dark ? 'blue-darken-5' : 'white-lighten-2'"
        >
          <v-sheet
            class="transactionTable ib-commission-table"
            :class="$vuetify.theme.dark ? 'blue-lighten-1' : 'white-lighten-1'"
          >
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="Transaction"
            ></v-data-table>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Account", align: "start", value: "account" },
        { text: "Ticket", value: "ticket" },
        { text: "Time", value: "time" },
        { text: "Lots", value: "lots" },
        { text: "Symbol", value: "symbol" },
        { text: "Balance", value: "balance" },
        { text: "Equity", value: "equity" },
        { text: "Profit", value: "profit" },
      ],
      Transaction: [
        {
          account: "2323334434",
          ticket: "we23r323r",
          time: "10.10.2023  10:20pm",
          lots: "1:10",
          symbol: "USDJPY",
          balance: "120",
          equity: "120",
          profit: "120",
        },
        {
          account: "2323334434",
          ticket: "we23r323r",
          time: "10.10.2023  10:20pm",
          lots: "1:10",
          symbol: "USDJPY",
          balance: "120",
          equity: "120",
          profit: "120",
        },
        {
          account: "2323334434",
          ticket: "we23r323r",
          time: "10.10.2023  10:20pm",
          lots: "1:10",
          symbol: "USDJPY",
          balance: "120",
          equity: "120",
          profit: "120",
        },
        {
          account: "2323334434",
          ticket: "we23r323r",
          time: "10.10.2023  10:20pm",
          lots: "1:10",
          symbol: "USDJPY",
          balance: "120",
          equity: "120",
          profit: "120",
        },
        {
          account: "2323334434",
          ticket: "we23r323r",
          time: "10.10.2023  10:20pm",
          lots: "1:10",
          symbol: "USDJPY",
          balance: "120",
          equity: "120",
          profit: "120",
        },
        {
          account: "2323334434",
          ticket: "we23r323r",
          time: "10.10.2023  10:20pm",
          lots: "1:10",
          symbol: "USDJPY",
          balance: "120",
          equity: "120",
          profit: "120",
        },
      ],
    };
  },
};
</script>