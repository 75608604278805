<template>
  <v-row dense>
    <v-col cols="6" sm="4" md="2" v-for="list in lists" :key="list.id">
      <v-card
        elevation="0"
        tile
        :to="list.link"
        :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-2'"
        class="widgets-card"
      >
        <v-btn
          class="mx-2 mb-3"
          fab
          link
          small
          elevation="0"
          :class="$vuetify.theme.dark ? 'blue-lighten-1 ' : 'white-lighten-4'"
        >
          <v-img
            max-width="17"
            :src="list.imageName"
            :alt="list.imageAlt"
          ></v-img>
        </v-btn>
        <p class="mb-0">{{ list.title }}</p>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8" md="4">
      <v-card
        elevation="0"
        tile
        :class="$vuetify.theme.dark ? 'blue-darken-2' : 'white-lighten-2'"
        class="copy-to-clipboard"
      >
        <p class="content d-flex align-center">
          <v-img
            max-width="14"
            class="mr-1"
            src="@/assets/Dashboard/copy.svg"
          ></v-img>

          Your IB Link
        </p>
        <div class="d-flex align-center justify--md-space-between">
          <p class="copy-link text-truncate">
            http://client.gicmarkets.com/register?ref_id=5003
          </p>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="copyCode"
                @mouseout="reset"
                style="cursor: pointer"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  tile
                  elevation="0"
                  small
                  class="ma-0 ml-2 text-capitalize btn-account"
                  color="teal-lighten-1"
                  
                >
                  <v-icon
                    class="mr-1"
                    left
                    x-small
                    
                    >mdi-content-copy</v-icon
                  >
                  <b>Copy</b>
                </v-btn>
              </span>
            </template>
            <span>{{ copyText }}</span>
          </v-tooltip>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      lists: [
        {
          id: 1,
          imageName: require("@/assets/Dashboard/transfer.svg"),
          imageAlt: "Transfer",
          link: "/transfer-form",
          title: "Transfer",
        },
        {
          id: 2,
          imageName: require("@/assets/Dashboard/withdraw.svg"),
          imageAlt: "Withdraw",
          link: "/withdraw",
          title: "Withdraw",
        },
        {
          id: 3,
          imageName: require("@/assets/Dashboard/myclient.svg"),
          imageAlt: "My Clients",
          link: "/my-clients",
          title: "My Clients",
        },
        {
          id: 4,
          imageName: require("@/assets/Dashboard/drone.svg"),
          imageAlt: "Drone View",
          link: "/drone-view",
          title: "Drone View",
        },
      ],
      invitationCode: "http://client.gicmarkets.com/register?ref_id=5003",
      text: "Copy to invite",
      copyText: "",
    };
  },
  created() {
    this.copyText = this.text;
  },
  methods: {
    async copyCode() {
      await navigator.clipboard.writeText(this.invitationCode);
      this.copyText = "Copied";
    },
    reset() {
      this.copyText = this.text;
    },
  },
};
</script>
